<template>
  <div class="Receivingprintdetails" v-if="info != ''">
    <div class="auto-table-flex">
      <div class="ctns">
        <div class="toptli">
          <div class="divtitle">
            <div class="titletab">订单号：{{ info.order_no }}</div>
            <div class="div">
              <div class="its">客户姓名：{{ info.member.fullname }}</div>
              <div class="its">客户电话：{{ info.member.mobile }}</div>
            </div>
            <div class="div">
              <div class="its">
                <span v-if="role == 'delivery'"
                  >城市仓：{{ info.delivery.logistics.name }}</span
                >
                <span v-else
                  >城市仓：{{ info.store_delivery.logistics.name }}</span
                >
              </div>
              <div class="its">
                <span v-if="role == 'delivery'"
                  >自提点信息：{{ info.delivery.name }}，{{
                    info.delivery.address
                  }}</span
                >
                <span v-else
                  >自提点信息：{{ info.store_delivery.name }}，{{
                    info.store_delivery.address
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="flexbox">
            <img :src="info.qrcode" alt="" />
            <div>订单号：{{ info.order_no }}</div>
          </div>
        </div>

        <el-table
          :data="info.order_detail"
          border
          style="width: 100%"
          :header-cell-style="{ color: '#333333', fontSize: '16px' }"
        >
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column prop="title" label="商品名称" align="center">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.title) }}({{
                $empty.empty(scope.row.product.sku_code)
              }})
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="等级">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.product.grade) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="毛重">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.gross_weight) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="净重">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.weight) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="业务价">
            <template slot-scope="scope">
              {{ tool.toDecimal2((scope.row.price * 10000) / 100 / 10000) }}
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="数量">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.qty) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btn">
      <el-button @click="fanhui">返回</el-button>
      <!-- <el-button type="primary" @click="printlist">打印</el-button> -->
    </div>
  </div>
</template>
<script>
import { BASE } from "../../api/index";
export default {
  name: "Receivingprintdetails",
  components: {},
  data() {
    return {
      tableData: [],
      info: "",
      role: sessionStorage.getItem("manageIsdentity"),
    };
  },
  created() {
    this.hqinfo();
  },
  methods: {
    hqinfo() {
      this.$api.delivery
        .orderdetailti({ order_no: this.$route.query.id })
        .then((res) => {
          console.log(res, "获取数据");
          this.info = res.data;
        });
    },
    printlist() {
      window.open(
        `${
          this.role == "delivery"
            ? BASE.PRO3 + "/order/print"
            : BASE.PRO4 + "/take/order/print"
        }` +
          `?ids=${this.info.storeDeliveryTakeOrderId}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
      // this.$api.delivery.orderlisttiprint({ id: [el.id] }).then((res) => {
      //   console.log(res, "打印数据");
      //   this.hqlist();
      //   window.open(
      //     res.data +
      //       `?token:${
      //         sessionStorage.getItem("token")
      //           ? sessionStorage.getItem("token")
      //           : ""
      //       }`
      //   );
      // });
    },
    fanhui() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.Receivingprintdetails {
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: auto;
    .ctns {
      padding: 23px 12px;
      box-sizing: border-box;
    }
  }
  .toptli {
    display: flex;
    .flexbox {
      text-align: center;
      img {
        width: 109px;
        height: 109px;
      }
      div {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .btn {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;

    box-sizing: border-box;
    justify-content: center;
  }

  .divtitle {
    flex: 1;
    .titletab {
      margin-bottom: 31px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .div {
      display: flex;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
      .its {
        flex: 1;
      }
    }
  }

  /**
  改变边框颜色
   */
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #666 !important;
  }
  /**
  改变表格内竖线颜色
   */
  /deep/ .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #666 !important;
  }
  /**
  改变表格内行线颜色
   */
  /deep/ .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #666 !important;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #666 !important;
  }

  /deep/ .el-table thead tr th {
    border-color: #666;
  }
  .rt {
    border-right: 1px solid #666666 !important;
  }
}
</style>
